import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Image from "gatsby-plugin-sanity-image"
import Layout from "../components/Layouts"
import styled from "styled-components"
import SEO from "../components/SEO"
const BottomSpacer = styled.div`
  margin-bottom: 400px;
  @media (max-width: 420px) {
    margin-bottom: 500px;
  }
  @media (max-width: 380px) {
    margin-bottom: 600px;
  }
`
const Title = styled.h1`
  text-align: center;
  padding: 20px;

`
const Description = styled.h4`
  text-align: center;
  padding: 20px;
  font-style: italic;
`
const JournalGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
`
const JournalEntry = styled.div`
  margin: 40px auto;
  text-align: center;
`
const JournalImage = styled(Image)`
  height: 350px;
  width: 350px;
  object-fit: cover;
`
const JournalTitle = styled.h3`
  text-align: center;
  margin-top: 20px;
  color: #ca0388;
`
const JournalDate = styled.h4`
  font-style: italic;
  padding: 10px;
`
const JournalLink = styled(Link)`
  text-decoration: none;
`
const Journal = ({ data }) => {
  
  return (
    <Layout>
      <SEO title="CATE Journal" />
      <Title>CATE Journal</Title>
      <Description></Description>
      <JournalGrid>
        {data.allSanityPost.nodes.map((post, i) => (
          <JournalEntry key={`${i}-${post.slug}`}>
          <JournalLink to={`/journal/${post.slug.current}`}><JournalImage {...post.mainImage} alt="image" /></JournalLink>
            <JournalDate>
              {post.publishedAt.split(" ").slice(-3).join(" ")}
            </JournalDate>
            <JournalLink to={`/journal/${post.slug.current}`}>
              <JournalTitle>{post.title}</JournalTitle>
            </JournalLink>
          </JournalEntry>
        ))}
      </JournalGrid>
      <BottomSpacer />
    </Layout>
  )
}
export default Journal

export const query = graphql`
  query BloqQuery {
    allSanityPost(sort: {fields: publishedAt, order: DESC}) {
      nodes {
        title
        publishedAt(formatString: "dddd MMMM Do, YYYY")
        _rawBody(resolveReferences: { maxDepth: 10 })
        member {
          name
          image {
            ...ImageWithPreview
          }
        }
        mainImage {
          ...ImageWithPreview
        }
        slug {
          current
        }
      }
    }
  }
`
